import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Muhammad Rizwan | Data Scientist | Project Manager | Developer', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Muhammad Rizwan',
  subtitle: 'I am the Project Manager / Data Scientist / Developer',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'Rizwan.jpg',
  paragraphOne:
    'I provide digital leadership to the organizations, from strategy to execution, globally and locally.  In my 25+ corporate career, I have worked in both public and private equity spaces with technology-led and digitally-enabled businesses in various management vernaculars, including C-Suite. ',
  paragraphTwo:
    'My experience includes software offerings from transaction recording to ERP. More recently, involved in providing businesses actionable intelligence.  I hold a certificate from Stanford University in Machine Learning. A Master Degree from Hamdard. Bachelors degree in Statistics/Commerce and Information Systems.  I also carry a diploma in Software Engineering. ',
  paragraphThree:
    'Currently, heading the information systems at Dollar Industries (Pvt) Ltd. I have worked for HinoPak Motors, Karachi Stock Exchange.',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'TWP.JPG',
    title: 'Trustworks.Global',
    info:
      'We are selling our services through our platform. Creators/artists/startups/projects online/offline who are trying to monetize their work. We will allow them to receive funding directly from their fans/supporters on a recurring basis or per product/art.',
    info2:
      'Through token offerings and marketing campaigns. It will provide them opportunities to monetize in an autoauditable, self governed and transparent framework, bridging trust between both parties. ',
    url: 'https://trustworks.global/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Pedger.com',
    info:
      'Planning Ledger for businesses, startups and scale ups. Integrated Business Planning allows business to visualize the driver / scenario based planning of business outcomes.',
    info2: 'We offer tools and training for doing IBP at an economical cost.',
    url: 'https://pedger.com/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'BAG.JPG',
    title: 'BuyaGift.Co.Uk',
    info:
      'Selling Experiences. It is an UK based project selling experiences as market intermediaries.',
    info2:
      'My role was to do data analysis using microsfot SSAS, and develop business intelligence dashboards for actionable intelligence.',
    url: 'https://www.buyagift.co.uk/',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'rizwanharun@hotmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/rizwanharun',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: '',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/rizwanharun/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/rizwanharun',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
